e-dialog {
  display: none;
}

e-dialog-container {
  height: 0;
  position: relative;
  width: 0;
  z-index: z("modal");
}

.e-dialog {
  align-items: center;
  backface-visibility: hidden;
  background-color: color-mix(in srgb, var(--token-overlay-dark) 60%, transparent);
  bottom: 0;
  display: none;
  flex-direction: column;
  justify-content: center;
  left: 0;
  opacity: 0;
  outline: none;
  padding: (size-unitless("shellbar", "horizonHeight") + $space-xl) $space-xl $space-xl
    (size-unitless("menu", "width") + $space-xl);
  position: fixed;
  right: 0;
  top: 0;
  transform: translateZ(0);
  transition: opacity var(--token-transition-time) ease-out;
  visibility: hidden;
  z-index: z("modal-backdrop");

  :where(e-float-container) > & {
    background: none;

    &:where(.e-dialog-last) {
      background-color: color-mix(in srgb, var(--token-overlay-dark) 60%, transparent);
    }

    &:where(.e-dialog-last:not(.e-dialog-first)) {
      transition: none;
    }
  }

  &-nonavigation {
    padding-left: $space-xl;
    padding-top: $space-xl;
  }

  &-iniframe {
    background-color: var(--token-overlay-dark);
    display: flex;
    flex-direction: column;
    height: 100%;
    opacity: 1;
    padding: 0;
    position: static;
    transition: none;
    visibility: visible;
  }

  &-visible {
    display: flex;
    visibility: visible;
  }

  &-local {
    position: absolute;
  }

  &-active {
    opacity: 1;
  }

  &-img {
    .e-dialog__content {
      text-align: center;
      user-select: none;

      img {
        margin: auto;
        vertical-align: top;
      }
    }
  }

  &-floatingpage {
    padding: size("shellbar", "horizonHeight") $space-m $space-m size("menu", "fullheightWidth");
  }

  &-iframe,
  &-floatingpage {
    .e-dialog__content {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 0;
      user-select: none;

      iframe {
        border: 0;
        flex: 1 1 auto;
        height: 100%;
        vertical-align: top;
        width: 100%;
      }
    }
  }

  &-tabs {
    .e-dialog__content {
      padding: $space-m;
    }
  }

  &__container {
    @include has-focus-state;

    background-color: var(--token-box-default-background);
    border: 1px solid var(--token-box-default-border);
    border-radius: $border-radius;
    box-shadow: var(--token-shadow-strong);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    max-height: 100%;
    max-width: size("base", 82);
    padding-top: $space-m;
    position: relative;
    text-align: left;
    width: 100%;

    &:focus-visible {
      outline: 0;
    }

    :where(e-float-container) & {
      box-shadow: none;
    }

    :where(.e-dialog-last) & {
      border: 1px solid var(--token-box-default-border);
      box-shadow: var(--token-shadow-strong);
    }

    &-floatingpage {
      height: 100%;
      max-width: 100%;
      padding-top: 0;
    }

    &-iniframe {
      border: 0;
      box-shadow: none;
      height: 100%;
      max-width: 100%;
    }
  }

  &-confirmation__headline_icon {
    margin-right: $space-xs;
    margin-top: -3px;
  }

  &__header {
    @include typography("heading4");

    align-items: flex-start;
    background-size: cover;
    border-bottom: 1px solid var(--token-box-default-border);
    display: flex;
    flex: 0 0 auto;
    gap: $space-xs;
    justify-content: space-between;
    margin: (-$space-m) 0 0;
    min-height: calc(var(--token-button-default-height) + (2 * $space-2xs));
    padding: $space-2xs $space-m;
    position: relative;

    &-cover {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      padding: ($space-xs + 1px) ($space-xs + 1px) $space-s ($space-m + 1px);
    }

    &-light {
      color: var(--token-neutral-000);
    }

    :where(.e-dialog-confirmation-destructive) & {
      border-color: var(--token-box-error-border);
    }
  }

  &__header_actions {
    display: inline-block;
    flex: 0 0 auto;
    height: var(--token-button-default-height);
  }

  &__title {
    align-self: center;
    flex: 1 1 auto;
  }

  &__headline-slot {
    @include typography("body");
  }

  &__close_tooltip {
    margin-left: auto;
  }

  &__content {
    border-radius: $border-radius;
    flex: 1 1 auto;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: $space-m;

    &-overflow {
      overflow: visible;
    }

    > img {
      vertical-align: top;
    }

    e-iframe-fit {
      display: block;
      height: 100%;
      position: relative;
      width: 100%;
    }
  }

  &__footer {
    @include typography("body");

    border-top: 1px solid var(--token-box-default-border);
    display: flex;
    flex: 0 0 auto;
    flex-direction: row-reverse;
    min-height: #{size-unitless("base", 6) + 1}px;
    padding: $space-xs $space-m;

    &_custom {
      display: flex;
      flex-direction: row;
      gap: $space-m;
      justify-content: flex-end;
    }

    &_slot {
      @include typography("body");

      align-items: center;
      display: flex;
      flex: 1 1 0;
      width: 100%;

      & > e-dialog-footer-slot {
        display: block;
        flex: 1 1 0;
      }
    }

    &_actions {
      flex: 0 0 0;

      e-dialog-footer-actions {
        display: flex;
        flex-direction: row-reverse;
        gap: $space-xs;
      }
    }

    .e-flex,
    .e-grid {
      flex-direction: row-reverse;
    }
  }

  &__confirmbutton-destructive {
    @extend .e-btn-error;
  }
}
